import { useContext, useState, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaSun, FaCogs, FaFileSignature, FaMoneyBillWave, FaEuroSign } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import ContratRetraite from "./ContratRetraite";
import ParametresPer from "./Contrats/ContratPer/ParametresPer";
import ParametresAssvie from "./Contrats/ContratAssvie/ParametresAssvie";
import ParametresArticle83 from "./Contrats/ContratArticle83/ParametresArticle83";
import ParametresMadelin from "./Contrats/ContratMadelin/ParametresMadelin";
import ParametresPee from "./Contrats/ContratPee/ParametresPee";
import ParametresPerco from "./Contrats/ContratPereco/ParametresPerco";
import ParametresPero from "./Contrats/ContratPero/ParametresPero";
import ParametresPerp from "./Contrats/ContratPerp/ParametresPerp";
import VersementPer from "./Contrats/ContratPer/VersementPer";
import PrestationsPer from "./Contrats/ContratPer/PrestationsPer";
import VersementPee from "./Contrats/ContratPee/VersementPee";
import PrestationsPerp from "./Contrats/ContratPerp/PrestationsPerp";
import VersementMadelin from "./Contrats/ContratMadelin/VersementMadelin";
import Versement83 from "./Contrats/ContratArticle83/Versement83";
import { RemExpertResultatSalarie } from "components/app/Api/ResultatRemExpertSalarieProvider";
import PrestationsMadelin from "./Contrats/ContratMadelin/PrestationsMadelin";
import ForfaitSocial from "./Contrats/ContratPee/ForfaitSocial";
import { TabTauxConversion } from "./TabTauxconversion";

const ContratRetraitePager = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const ResultatSalarie = useContext(RemExpertResultatSalarie);

  //PARAMETRAGE AUTO DE LA CSG PRECOMPTE POUR UN PEE/PERECO
  useEffect(() => {
    context.handleValueChange("PayeurPrimeRetraite", props.PayeurPrime);
    if (props.visible === true) {
      if (props.Statut === "Assimilé salarié") {
        context.handleValueChange("CSGPERCOContratRetraite", "oui");
      } else {
        context.handleValueChange("CSGPERCOContratRetraite", "non");
      }
    }
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);

  //RECUPERATION DU SALAIRE BRUT POUR UN ARTICLE 83/PERO

  useEffect(() => {
    if (context.state.TypeSimulation === "Audit rémunération" && props.Outil === "") {
      context.handleValueChange("SalaireBrutContratRetraite", context.state.RemExpressRemBrute);
    } else if (props.Outil === "OptiRem") {
      context.handleValueChange("SalaireBrutContratRetraite" + props.ClientConjoint, context.state["OptiRemRemBrute" + props.ClientConjoint]);
    } else if (
      context.state.TypeSimulation === "Audit complet" &&
      props.PayeurPrime === "Entreprise" &&
      (context.state.FiscaliteContratRetraite === "Article83" || context.state.FiscaliteContratRetraite === "Pero")
    ) {
      const newPeriode = [
        {
          SituationPro: context.state.SituationPro,
          DebutPeriodeCarriereActuelle: context.state.DebutPeriodeCarriereActuelle,
          FinCarriereActuelle: context.state.FinCarriereActuelle,
          FinPeriodeCarriereActuelle: context.state.FinPeriodeCarriereActuelle,
          FinCarriereActuelleConjoint: context.state.FinCarriereActuelleConjoint,
          FinPeriodeCarriereActuelleConjoint: context.state.FinPeriodeCarriereActuelleConjoint,
          Statut: context.state.Statut,
          StatutConjoint: context.state.StatutConjoint,
          Caisse: context.state.Caisse,
          ConventionneCarcdsf: context.state.ConventionneCarcdsf,
          ConventionneCarmf: context.state.ConventionneCarmf,
          OptionRsi: context.state.OptionRsi,
          ConventionneCarpimko: context.state.ConventionneCarpimko,
          TauxCpam: context.state.TauxCpam,
          ClassePrevoyanceCarpv: context.state.ClassePrevoyanceCarpv,
          ClasseRetraiteCarpv: context.state.ClasseRetraiteCarpv,
          ClassePrevoyanceCavec: context.state.ClassePrevoyanceCavec,
          ClasseRetraiteCavec: context.state.ClasseRetraiteCavec,
          ConventionneCavp: context.state.ConventionneCavp,
          ClasseRetraiteCavp: context.state.ClasseRetraiteCavp,
          ClassePrevoyanceCipav: context.state.ClassePrevoyanceCipav,
          ClasseRetraiteCipav: context.state.ClasseRetraiteCipav,
          AncienneteCnbf: context.state.AncienneteCnbf,
          ClasseRetraiteCnbf: context.state.ClasseRetraiteCnbf,
          ClasseRetraiteCrn: context.state.ClasseRetraiteCrn,
          CrnSermentAvt2014: context.state.CrnSermentAvt2014,
          CrnColmarMetz: context.state.CrnColmarMetz,
          Atexa: context.state.Atexa,
          TauxActivite: context.state.TauxActivite,
          Effectif: context.state.Effectif,
          CavecSalarie: context.state.CavecSalarie,
          CnbfSalarie: context.state.CnbfSalarie,
          AccidentTravail: context.state.AccidentTravail,
          AgircArrcoT1Sal: context.state.AgircArrcoT1Sal,
          AgircArrcoT1Pat: context.state.AgircArrcoT1Pat,
          AgircArrcoT2Sal: context.state.AgircArrcoT2Sal,
          AgircArrcoT2Pat: context.state.AgircArrcoT2Pat,
          Transport: context.state.Transport,
          Formation: context.state.Formation,
          Apprentissage: context.state.Apprentissage,
          Regime: context.state.Regime,
          TaxeSalaire: context.state.TaxeSalaire,
          AssuranceChomage: context.state.AssuranceChomage,
          ReductionGenerale: context.state.ReductionGenerale,
          AvantageNature: context.state.AvantageNature,
          TabCotisationsManuelle: context.state.TabCotisationsManuelle,
          ConventionneCarcdsfConjoint: context.state.ConventionneCarcdsfConjoint,
          ConventionneCarmfConjoint: context.state.ConventionneCarmfConjoint,
          OptionRsiConjoint: context.state.OptionRsiConjoint,
          ConventionneCarpimkoConjoint: context.state.ConventionneCarpimkoConjoint,
          TauxCpamConjoint: context.state.TauxCpamConjoint,
          ClassePrevoyanceCarpvConjoint: context.state.ClassePrevoyanceCarpvConjoint,
          ClasseRetraiteCarpvConjoint: context.state.ClasseRetraiteCarpvConjoint,
          ClassePrevoyanceCavecConjoint: context.state.ClassePrevoyanceCavecConjoint,
          ClasseRetraiteCavecConjoint: context.state.ClasseRetraiteCavecConjoint,
          ConventionneCavpConjoint: context.state.ConventionneCavpConjoint,
          ClasseRetraiteCavpConjoint: context.state.ClasseRetraiteCavpConjoint,
          ClassePrevoyanceCipavConjoint: context.state.ClassePrevoyanceCipavConjoint,
          ClasseRetraiteCipavConjoint: context.state.ClasseRetraiteCipavConjoint,
          AncienneteCnbfConjoint: context.state.AncienneteCnbfConjoint,
          ClasseRetraiteCnbfConjoint: context.state.ClasseRetraiteCnbfConjoint,
          ClasseRetraiteCrnConjoint: context.state.ClasseRetraiteCrnConjoint,
          CrnSermentAvt2014Conjoint: context.state.CrnSermentAvt2014Conjoint,
          CrnColmarMetzConjoint: context.state.CrnColmarMetzConjoint,
          AtexaConjoint: context.state.AtexaConjoint,
          TauxActiviteConjoint: context.state.TauxActiviteConjoint,
          EffectifConjoint: context.state.EffectifConjoint,
          CavecSalarieConjoint: context.state.CavecSalarieConjoint,
          AccidentTravailConjoint: context.state.AccidentTravailConjoint,
          AgircArrcoT1SalConjoint: context.state.AgircArrcoT1SalConjoint,
          AgircArrcoT1PatConjoint: context.state.AgircArrcoT1PatConjoint,
          AgircArrcoT2SalConjoint: context.state.AgircArrcoT2SalConjoint,
          AgircArrcoT2PatConjoint: context.state.AgircArrcoT2PatConjoint,
          TransportConjoint: context.state.TransportConjoint,
          FormationConjoint: context.state.FormationConjoint,
          ApprentissageConjoint: context.state.ApprentissageConjoint,
          RegimeConjoint: context.state.RegimeConjoint,
          TaxeSalaireConjoint: context.state.TaxeSalaireConjoint,
          AssuranceChomageConjoint: context.state.AssuranceChomageConjoint,
          ReductionGeneraleConjoint: context.state.ReductionGeneraleConjoint,
          TabCotisationsManuelleConjoint: context.state.TabCotisationsManuelleConjoint,
          IdentiteEntreprise: context.state.IdentiteEntreprise,
          FormeJuridique: context.state.FormeJuridique,
          Fiscalite: context.state.Fiscalite,
          TauxISReduit: context.state.TauxISReduit,
          TauxISNormal: context.state.TauxISNormal,
          TauxISZero: context.state.TauxISZero,
          TypeRemuneration: context.state.TypeRemuneration,
          TypeRemunerationConjoint: context.state.TypeRemunerationConjoint,
          Remuneration: context.state.Remuneration,
          RemunerationConjoint: context.state.RemunerationConjoint,
          AvantageNatureConjoint: context.state.AvantageNatureConjoint,
          DividendeBrut: context.state.DividendeBrut,
          DividendeBrutAutres: context.state.DividendeBrutAutres,
          CapitalSocial: context.state.CapitalSocial,
          PartSoc: context.state.PartSoc,
          PartSocConj: context.state.PartSocConj,
          ResAvtIs: context.state.ResAvtIs,
          ImpotSoc: context.state.ImpotSoc,
          ResNet: context.state.ResNet,
          SeuilAssuj: context.state.SeuilAssuj,
          SeuilAssujConjoint: context.state.SeuilAssujConjoint,
          SetSeuilAssuj: context.state.SetSeuilAssuj,
          SetSeuilAssujConjoint: context.state.SetSeuilAssujConjoint,
          Reserve: context.state.Reserve,
        },
      ];
      ResultatSalarie.GetSalaireBrutCalculPrestation(context.state, newPeriode, props.CliConj).then((SalaireBrut) => {
        context.handleValueChange("SalaireBrutContratRetraite" + props.ClientConjoint, SalaireBrut);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.FiscaliteContratRetraite]);

  const handleMenuRetraite = () => {
    let menu = [
      {
        isTitle: true,
        title: "Contrat",
        subTitle: "Contrat de retraite",
      },
      {
        label: "Contrat",
        icon: <FaFileSignature />,
        content: (
          <ContratRetraite ClientConjoint={props.ClientConjoint} Outil={props.Outil} Statut={props.Statut} AccessRemExpress={props.AccessRemExpress} PayeurPrime={props.PayeurPrime} />
        ),
      },
    ];
    let m = [];

    switch (context.state.FiscaliteContratRetraite) {
      case "Article62":
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <VersementMadelin AccessRemExpress={props.AccessRemExpress} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresAssvie TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsPer ClientConjoint={props.ClientConjoint} />,
          },
        ];
        menu = [...menu, ...m];
        break;
      case "Article83":
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <Versement83 AccessRemExpress={props.AccessRemExpress} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresArticle83 TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsMadelin ClientConjoint={props.ClientConjoint} SalaireBrut83PERO={context.state["SalaireBrutContratRetraite" + props.ClientConjoint]} />,
          },
        ];
        menu = [...menu, ...m];
        break;
      case "Pero":
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <Versement83 AccessRemExpress={props.AccessRemExpress} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresPero TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsMadelin ClientConjoint={props.ClientConjoint} SalaireBrut83PERO={context.state["SalaireBrutContratRetraite" + props.ClientConjoint]} />,
          },
        ];
        menu = [...menu, ...m];
        break;
      case "Madelin":
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <VersementMadelin AccessRemExpress={props.AccessRemExpress} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresMadelin TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsMadelin ClientConjoint={props.ClientConjoint} />,
          },
        ];
        menu = [...menu, ...m];
        break;
      case "PEE":
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <VersementPee />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresPee TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Forfait social",
            icon: <FaEuroSign />,
            content: <ForfaitSocial Outil={props.Outil} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsPer ClientConjoint={props.ClientConjoint} />,
          },
        ];
        menu = [...menu, ...m];
        break;
      case "PERCO":
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <VersementPee />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresPerco TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Forfait social",
            icon: <FaEuroSign />,
            content: <ForfaitSocial Outil={props.Outil} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsPer ClientConjoint={props.ClientConjoint} />,
          },
        ];
        menu = [...menu, ...m];
        break;

      case "Perp":
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <VersementMadelin AccessRemExpress={props.AccessRemExpress} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresPerp TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsPerp ClientConjoint={props.ClientConjoint} />,
          },
        ];
        menu = [...menu, ...m];
        break;

      default:
        m = [
          {
            label: "Versement",
            icon: <FaMoneyBillWave />,
            content: <VersementPer AccessRemExpress={props.AccessRemExpress} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Paramètres",
            icon: <FaCogs />,
            content: <ParametresPer TabTauxConversion={TabTauxConversion} ClientConjoint={props.ClientConjoint} />,
          },
          {
            label: "Prestations",
            icon: <FaSun />,
            content: <PrestationsPer ClientConjoint={props.ClientConjoint} />,
          },
        ];
        menu = [...menu, ...m];
    }

    if (props.AccessRemExpress === true) {
      menu = menu.filter((k) => k.label !== "Paramètres");
      menu = menu.filter((k) => k.label !== "Prestations");
    }

    if (context.state.StatutContratRetraite === "Mis en réduction") {
      menu = menu.filter((k) => k.label !== "Versement");
    }
    return menu;
  };

  return (
    <>
      <Pager
        id={"contratFacRetraite" + props.PayeurPrime}
        withMenu
        pages={handleMenuRetraite()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          cancel: {
            onClick: (evt) => {
              context.handleResetContratRetraite();
              props?.onClose?.();
            },
          },
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              context.handleAddContratRetraite(context.state.IdContratRetraite, props.Outil, props.ClientConjoint, context.state["SalaireBrutContratRetraite" + props.ClientConjoint]);
              props?.onClose?.();
            },
          },
        }}
      />
    </>
  );
};

export default ContratRetraitePager;
