import React from "react";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";

const DetailTrimestreRU = ({ InfosTrimestres }) => {
  let TotalTrimRU = 0;
  let TotalTrimAvpf = 0;
  let TotalTrimTrimSpe = 0;
  let TotalTrimMilitaire = 0;
  let TotalTrimChomage = 0;
  let TotalTrimInvalidite = 0;
  let TotalTrimMaladie = 0;
  let TotalTrimMaternite = 0;
  for (const annee in InfosTrimestres?.TabTrimestreRU) {
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("RegimeUnifie")) {
      TotalTrimRU += InfosTrimestres?.TabTrimestreRU[annee]["RegimeUnifie"];
    }
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("Avpf")) {
      TotalTrimAvpf += InfosTrimestres?.TabTrimestreRU[annee]["Avpf"];
    }
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("TrimSpecifique")) {
      TotalTrimTrimSpe += InfosTrimestres?.TabTrimestreRU[annee]["TrimSpecifique"];
    }
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("Militaire")) {
      TotalTrimMilitaire += InfosTrimestres?.TabTrimestreRU[annee]["Militaire"];
    }
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("Chomage")) {
      TotalTrimChomage += InfosTrimestres?.TabTrimestreRU[annee]["Chomage"];
    }
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("Invalidite")) {
      TotalTrimInvalidite += InfosTrimestres?.TabTrimestreRU[annee]["Invalidite"];
    }
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("Maladie")) {
      TotalTrimMaladie += InfosTrimestres?.TabTrimestreRU[annee]["Maladie"];
    }
    if (InfosTrimestres?.TabTrimestreRU[annee].hasOwnProperty("Maternite")) {
      TotalTrimMaternite += InfosTrimestres?.TabTrimestreRU[annee]["Maternite"];
    }
  }
  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <table>
              <thead>
                <tr>
                  <th className="text-center">Année</th>
                  <th className="text-center">Trim. cotisés</th>
                  <th className="text-center">Avpf</th>
                  <th className="text-center">Trim. spécifiques <br/>cotisés</th>
                  <th className="text-center">Militaire</th>
                  <th className="text-center">Chômage</th>
                  <th className="text-center">Maladie</th>
                  <th className="text-center">Maternité</th>
                  <th className="text-center">Invalidité</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(InfosTrimestres?.TabTrimestreRU).map((data, index) => {
                  return (
                    <tr key={"DetailRU" + index}>
                      <td className="text-center">{data[0]}</td>
                      <td className="text-center">{data[1].hasOwnProperty("RegimeUnifie") ? data[1]["RegimeUnifie"] : ""}</td>
                      <td className="text-center">{data[1].hasOwnProperty("Avpf") ? data[1]["Avpf"] : ""}</td>
                      <td className="text-center">{data[1].hasOwnProperty("TrimSpecifique") ? data[1]["TrimSpecifique"] : ""}</td>
                      <td className="text-center">{data[1].hasOwnProperty("Militaire") ? data[1]["Militaire"] : ""}</td>
                      <td className="text-center">{data[1].hasOwnProperty("Chomage") ? data[1]["Chomage"] : ""}</td>
                      <td className="text-center">{data[1].hasOwnProperty("Maladie") ? data[1]["Maladie"] : ""}</td>
                      <td className="text-center">{data[1].hasOwnProperty("Maternite") ? data[1]["Maternite"] : ""}</td>
                      <td className="text-center">{data[1].hasOwnProperty("Invalidite") ? data[1]["Invalidite"] : ""}</td>
                    </tr>
                  );
                })}
                <tr className="light-title">
                  <td className="text-center">Total</td>
                  <td className="text-center">{TotalTrimRU}</td>
                  <td className="text-center">{TotalTrimAvpf}</td>
                  <td className="text-center">{TotalTrimTrimSpe}</td>
                  <td className="text-center">{TotalTrimMilitaire}</td>
                  <td className="text-center">{TotalTrimChomage}</td>
                  <td className="text-center">{TotalTrimMaladie}</td>
                  <td className="text-center">{TotalTrimMaternite}</td>
                  <td className="text-center">{TotalTrimInvalidite}</td>
                </tr>
              </tbody>
            </table>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailTrimestreRU;

//    <FormatLignePension
//   key={index++}
//   caisse={"RSI Commerçant"}
//   points={data[1]["General"]["TotalPoint"]}
//   valeurPoint={data[1]["General"]["ValeurPoint"]}
//   pensionTheo={data[1]["General"]["PensionTheorique"]}
//   coeff={data[1]["General"]["Coefficient"]}
//   majoEnfant={"-"}
//   pension={data[1]["General"]["Pension"]}
// />
