import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaInfo, FaPrint, FaSync } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import TextField from "components/ui/controls/text-field/text-field";
import Hover from "components/ui/controls/hover/hover";
import StateBox from "components/ui/controls/state-box/state-box";
import _ from "lodash";

const RetraiteProgressivePage = (props) => {
  const CorrespondanceActivite = {
    ActiviteSalarie: "Activité salariée",
    SalarieNonCadre: "Salarié non cadre du  secteur privé",
    SalarieCadre: "Salarié cadre du  secteur privé",
    SalarieNonCadreCFE: "Salarié non cadre cotisant CFE",
    SalarieCadreCFE: "Salarié cadre cotisant CFE",
    SalarieNonCadreAgricole: "Salarié non cadre du secteur agricole",
    SalarieCadreAgricole: "Salarié cadre du secteur agricole",
    SalarieIrcantec: "Salarié non titulaire de l’Etat",
    CavecSalarie: "Expert comptable salarié",
    CnbfSalarie: "Avocat salarié",
    FonctionPublique: "Fonction publique",
    Avpf: "Assurance vieillesse des parents au foyer",
    Chomage: "Chômage et assimilé",
    Maladie: "Maladie",
    Maternite: "Maternité",
    Invalidite: "Invalidité",
    Militaire: "Militaire, guerre",
    TrimSpecifique: "Trimestres spécifiques",
    RsiArtisan: "Artisan",
    Cnbf: "Avocat",
    Carpimko: "Auxiliaire médical",
    Carcdsf: "Chirurgien-dentiste",
    RsiCommercant: "Commerçant",
    MicroRSI: "Micro-entrepreneur ",
    MicroBIC: "Micro-entrepreneur ",
    MicroBNC: "Micro-entrepreneur ",
    MicroCipav: "Micro-entrepreneur ",
    Cavec: "Expert-comptable",
    Cavamac: "Agent général",
    Crn: "Notaire",
    Msa: "Exploitant agricole",
    Cipav: "Ingénieur conseil",
    Cavom: "Officier min., pub. ou judi.",
    Carmf: "Médecin",
    Cavp: "Pharmacien",
    Carcdsf2: "Sage-femme",
    Carpv: "Vétérinaire",
    Crpcen: "Clerc de notaire",
  };
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const ClientConjoint = props.ClientConjoint;
  const Prenom = context.state["Prenom" + ClientConjoint];
  const [ageRetraiteProgressive, setAgeRetraiteProgressive] = useState(null);
  const [moisRetraiteProgressive, setMoisRetraiteProgressive] = useState(null);
  const [ageLiquidationRetraite, setAgeLiquidationRetraite] = useState(null);
  const [moisLiquidationRetraite, setMoisLiquidationRetraite] = useState(null);
  const [modalErrorDateDebut, setModalErrorDateDebut] = useState(false);
  const [modalErrorDate1AnMin, setModalErrorDate1AnMin] = useState(false);
  const [modalDateDepartTNS, setModalDateDepartTNS] = useState(false);
  const [periodesForRetraiteProgressive, setPeriodesForRetraiteProgressive] = useState([]);

  useEffect(() => {
    ResultatTNS.GetResultBilanRetraite(context.state, ClientConjoint).then((ResultatRetraite) => {
      context.handleValueChange("RetraiteProgressiveRevenus" + ClientConjoint, 0);
      context.handleValueChange("RetraiteProgressiveMoyenneRevenus" + ClientConjoint, 0);
      //AGE DE DEBUT
      let DateDebut = context.state["RetraiteProgDateDebut" + ClientConjoint];
      if (context.state["RetraiteProgDateDebutSaisi" + ClientConjoint] === false) {
        context.handleValueChange("RetraiteProgDateDebut" + ClientConjoint, ResultatRetraite.DateDebutRetraiteProgressive);
        DateDebut = ResultatRetraite.DateDebutRetraiteProgressive;
      } else {
        DateDebut = handleCheckDateDebutRetrProg(context.state["RetraiteProgDateDebut" + ClientConjoint]);
      }
      calculateAgeRetraiteProgressive(context.state["DateNaissance" + ClientConjoint], DateDebut);

      //AGE DE LIQUIDATION
      let DateLiquidation = context.state["RetraiteProgDateFin" + ClientConjoint];
      if (context.state["RetraiteProgDateFinSaisi" + ClientConjoint] === false) {
        context.handleValueChange("RetraiteProgDateFin" + ClientConjoint, ResultatRetraite.DateDepartRetraiteProgressive);
        DateLiquidation = ResultatRetraite.DateDepartRetraiteProgressive;
      }
      calculateAgeRetraiteLiquidation(context.state["DateNaissance" + ClientConjoint], DateLiquidation);

      //RECUPERATION DES PERIODES JUSQU'AU DEPART EN RETRAITE
      let PeriodesRetraiteProgressive = [];
      context.state["Carriere" + ClientConjoint].forEach((periode) => {
        if (
          periode.Nature !== "Crpcen" &&
          periode.Nature !== "Avpf" &&
          periode.Nature !== "Chomage" &&
          periode.Nature !== "Maladie" &&
          periode.Nature !== "Maternite" &&
          periode.Nature !== "Invalidite" &&
          periode.Nature !== "Militaire" &&
          periode.Nature !== "TrimSpecifique" &&
          periode.Nature !== "MicroRSI" &&
          periode.Nature !== "MicroBIC" &&
          periode.Nature !== "MicroBNC" &&
          periode.Nature !== "MicroCipav"
        ) {
          if (periode.CaseDepartRetraite === true) {
            PeriodesRetraiteProgressive.push({ id: periode.id, debut: periode.Debut, fin: periode.Fin, nature: periode.Nature, selected: false });
          }
        }
      });
      setPeriodesForRetraiteProgressive(PeriodesRetraiteProgressive);
      if (context.state["IndiceMajoreValeur" + ClientConjoint] !== "") {
        context.handleValueChange("RetraiteProgressiveIFCFin" + ClientConjoint, parseInt(context.state["IndiceMajoreValeur" + ClientConjoint]));
      }
    });
  }, []);

  const handleResetDateDebutRetraiteProgressive = () => {
    context.handleValueChange("RetraiteProgDateDebutSaisi" + ClientConjoint, false);
    context.handleValueChange("RetraiteProgDateDebut" + ClientConjoint, ResultatTNS.state["Retraite" + ClientConjoint]["DateDebutRetraiteProgressive"]);
    calculateAgeRetraiteProgressive(context.state["DateNaissance" + ClientConjoint], ResultatTNS.state["Retraite" + ClientConjoint]["DateDebutRetraiteProgressive"]);
  };

  const handleCheckDateDebutRetrProg = (dateSaisie) => {
    if (dateSaisie.length !== 8) {
      handleResetDateDebutRetraiteProgressive();
    } else {
      let AnneeDebut = dateSaisie.slice(-4);
      let MoisDebut = dateSaisie.slice(2, 4);
      let JourDebut = "01";

      let AnneeFin = ResultatTNS.state["Retraite" + ClientConjoint]["DateDebutRetraiteProgressive"].slice(-4);
      let MoisFin = ResultatTNS.state["Retraite" + ClientConjoint]["DateDebutRetraiteProgressive"].slice(2, 4);
      let JouFin = ResultatTNS.state["Retraite" + ClientConjoint]["DateDebutRetraiteProgressive"].slice(0, 2);
      const diffTime = new Date(AnneeFin, Number(MoisFin) - 1, JouFin) - new Date(AnneeDebut, Number(MoisDebut) - 1, JourDebut);
      const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      let dateDebut = JourDebut + MoisDebut + AnneeDebut;
      if (totalDays > 0) {
        dateDebut = ResultatTNS.state["Retraite" + ClientConjoint]["DateDebutRetraiteProgressive"];
        context.handleValueChange("RetraiteProgDateDebut" + ClientConjoint, dateDebut);
        setModalErrorDateDebut(true);
      } else {
        context.handleValueChange("RetraiteProgDateDebut" + ClientConjoint, dateDebut);
      }
      calculateAgeRetraiteProgressive(context.state["DateNaissance" + ClientConjoint], dateDebut);
      handleCheckDateDebutLiquidation1AnMin(dateDebut, context.state["RetraiteProgDateFin" + ClientConjoint]);
      return dateDebut;
    }
  };

  const handleResetDateLiquidationRetraiteProgressive = () => {
    context.handleValueChange("RetraiteProgDateFinSaisi" + ClientConjoint, false);
    context.handleValueChange("RetraiteProgDateFin" + ClientConjoint, ResultatTNS.state["Retraite" + ClientConjoint]["DateDepartRetraiteProgressive"]);
    handleSetDateFinPeriode(ResultatTNS.state["Retraite" + ClientConjoint]["DateDepartRetraiteProgressive"]);
    calculateAgeRetraiteLiquidation(context.state["DateNaissance" + ClientConjoint], ResultatTNS.state["Retraite" + ClientConjoint]["DateDepartRetraiteProgressive"]);
  };

  const handleCheckDateLiquidationRetrProg = (dateSaisie) => {
    let Annee = dateSaisie.slice(-4);
    let Mois = dateSaisie.slice(2, 4);
    let dateLiquidation = "01" + Mois + Annee;
    if (dateSaisie.length !== 8) {
      handleResetDateLiquidationRetraiteProgressive();
      dateLiquidation = ResultatTNS.state["Retraite" + ClientConjoint]["DateDepartRetraiteProgressive"];
    } else {
      context.handleValueChange("RetraiteProgDateFin" + ClientConjoint, dateLiquidation);
    }

    handleSetDateFinPeriode(dateLiquidation);
    calculateAgeRetraiteLiquidation(context.state["DateNaissance" + ClientConjoint], dateLiquidation);
    handleCheckDateDebutLiquidation1AnMin(context.state["RetraiteProgDateDebut" + ClientConjoint], dateLiquidation);
  };
  const handleSetDateFinPeriode = (date) => {
    let AnneeFin = date.slice(-4);
    let MoisFin = date.slice(2, 4);
    let JouFin = date.slice(0, 2);
    let d = new Date(AnneeFin, Number(MoisFin) - 1, JouFin);
    d.setDate(d.getDate() - 1); // subtract 1 days
    var isoDateTime = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().split("T")[0];

    let periodes = _.cloneDeep(periodesForRetraiteProgressive);
    periodes.forEach((periode) => {
      periode.fin = isoDateTime.slice(-2) + isoDateTime.slice(5, 7) + isoDateTime.slice(0, 4);
    });
    setPeriodesForRetraiteProgressive(periodes);
  };
  const handleCheckDateDebutLiquidation1AnMin = (dateDebut, dateFin) => {
    let AnneeDebut = dateDebut.slice(-4);
    let MoisDebut = dateDebut.slice(2, 4);
    let JourDebut = dateDebut.slice(0, 2);

    let AnneeFin = dateFin.slice(-4);
    let MoisFin = dateFin.slice(2, 4);
    let JouFin = dateFin.slice(0, 2);
    const diffTime = new Date(AnneeFin, Number(MoisFin) - 1, JouFin) - new Date(AnneeDebut, Number(MoisDebut) - 1, JourDebut);
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    if (totalDays < 365) {
      setModalErrorDate1AnMin(true);
      handleResetDateDebutRetraiteProgressive();
      handleResetDateLiquidationRetraiteProgressive();
    }
  };

  const calculateAgeRetraiteLiquidation = (birthDate, endDate) => {
    let AnneeNaissance = birthDate.slice(-4);
    let MoisNaissance = birthDate.slice(2, 4);
    let JourNaissance = birthDate.slice(0, 2);

    let AnneeRetraiteProg = endDate.slice(-4);
    let MoisRetraiteProg = endDate.slice(2, 4);
    let JouRetraiteProg = endDate.slice(0, 2);
    if (JourNaissance === "01") {
      JouRetraiteProg += 1;
    }
    const diffTime = new Date(AnneeRetraiteProg, Number(MoisRetraiteProg) - 1, JouRetraiteProg) - new Date(AnneeNaissance, Number(MoisNaissance) - 1, JourNaissance);
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    setAgeLiquidationRetraite(Math.floor(totalDays / 365.25));
    setMoisLiquidationRetraite(Math.floor((totalDays % 365.25) / 30.4375));
    // setDays(Math.floor((totalDays % 365.25) % 30.4375));
  };

  const calculateAgeRetraiteProgressive = (birthDate, endDate) => {
    let AnneeNaissance = birthDate.slice(-4);
    let MoisNaissance = birthDate.slice(2, 4);
    let JourNaissance = birthDate.slice(0, 2);

    let AnneeRetraiteProg = endDate.slice(-4);
    let MoisRetraiteProg = endDate.slice(2, 4);
    let JouRetraiteProg = endDate.slice(0, 2);
    if (JourNaissance === "01") {
      JouRetraiteProg += 1;
    }
    const diffTime = new Date(AnneeRetraiteProg, Number(MoisRetraiteProg) - 1, JouRetraiteProg) - new Date(AnneeNaissance, Number(MoisNaissance) - 1, JourNaissance);
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    setAgeRetraiteProgressive(Math.floor(totalDays / 365.25));
    setMoisRetraiteProgressive(Math.floor((totalDays % 365.25) / 30.4375));
    // setDays(Math.floor((totalDays % 365.25) % 30.4375));
  };

  const handleSelectActiviteRetraiteProgressive = (id) => (event) => {
    let periodes = _.cloneDeep(periodesForRetraiteProgressive);
    let nature = "";
    if (event.target.value === true) {
      periodes.forEach((periode) => {
        if (periode.id === id) {
          periode.selected = true;
          nature = periode.nature;
        } else {
          periode.selected = false;
        }
      });
      if (
        nature === "RsiArtisan" ||
        nature === "Cnbf" ||
        nature === "Carpimko" ||
        nature === "Carcdsf" ||
        nature === "RsiCommercant" ||
        nature === "Cavec" ||
        nature === "Cavamac" ||
        nature === "Crn" ||
        nature === "Cipav" ||
        nature === "Cavom" ||
        nature === "Carmf" ||
        nature === "Cavp" ||
        nature === "Carcdsf2" ||
        nature === "Carpv"
      ) {
        setModalDateDepartTNS(true);
      }
    }
    setPeriodesForRetraiteProgressive(periodes);
    handleBorneTauxActivite(nature);
  };

  const handleBorneTauxActivite = (nature) => {
    if (
      nature === "SalarieNonCadre" ||
      nature === "SalarieCadre" ||
      nature === "SalarieNonCadreCFE" ||
      nature === "SalarieCadreCFE" ||
      nature === "SalarieNonCadreAgricole" ||
      nature === "SalarieCadreAgricole" ||
      nature === "SalarieIrcantec" ||
      nature === "CavecSalarie" ||
      nature === "CnbfSalarie"
    ) {
      context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 80);
    } else if (nature === "Msa") {
      context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 60);
    } else if (nature === "FonctionPublique") {
      context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 50);
    }
  };
  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox fill>
              <Column fill>
                <Row>
                  <h1>Retraite progressive</h1>
                </Row>

                <Row tpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left " style={{ color: "black", width: "290px" }} value={"Date de début"} />
                  </Column>
                  <Column>
                    <TextField
                      iconBefore={
                        <Button
                          icon
                          onClick={(e) => {
                            handleResetDateDebutRetraiteProgressive();
                          }}
                        >
                          {!context.state["RetraiteProgDateDebutSaisi" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                      value={context.state["RetraiteProgDateDebut" + ClientConjoint]}
                      onKeyUp={(e) => {
                        context.handleValueChange("RetraiteProgDateDebutSaisi" + ClientConjoint, true);
                      }}
                      onChange={context.handleInputChange("RetraiteProgDateDebut" + ClientConjoint)}
                      onBlur={(evt, unformatted) => {
                        handleCheckDateDebutRetrProg(unformatted);
                      }}
                      dataType="date"
                    />
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    <Hover icon={<FaInfo />}>
                      La date de début de la retraite progressive doit être comprise entre la date à laquelle l'assuré a atteint son âge légal moins deux ans et la date à laquelle l'assuré
                      atteint l'âge de 66 ans.
                    </Hover>
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    soit {ageRetraiteProgressive} ans {moisRetraiteProgressive > 0 && " et " + moisRetraiteProgressive + " mois"}
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField disabled className="invisibleRachatTrimestre-left " style={{ color: "black", width: "290px" }} value={"Date de liquidation définitive"} />
                  </Column>
                  <Column>
                    <TextField
                      iconBefore={
                        <Button
                          icon
                          onClick={(e) => {
                            handleResetDateLiquidationRetraiteProgressive();
                          }}
                        >
                          {!context.state["RetraiteProgDateFinSaisi" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                      value={context.state["RetraiteProgDateFin" + ClientConjoint]}
                      onKeyUp={(e) => {
                        context.handleValueChange("RetraiteProgDateFinSaisi" + ClientConjoint, true);
                      }}
                      onChange={context.handleInputChange("RetraiteProgDateFin" + ClientConjoint)}
                      onBlur={(evt, unformatted) => {
                        handleCheckDateLiquidationRetrProg(unformatted);
                      }}
                      dataType="date"
                    />
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    <Hover icon={<FaInfo />}>Indiquez la date à laquelle vous souhaitez que l'ensemble des droits retraite soient liquidés de manière définitive.</Hover>
                  </Column>
                  <Column style={{ paddingTop: "14px" }} lpad>
                    soit {ageLiquidationRetraite} ans {moisLiquidationRetraite > 0 && " et " + moisLiquidationRetraite + " mois"}
                  </Column>
                </Row>
                <Row tpad>
                  <h1>Activité poursuivie</h1>
                </Row>
                <Row tpad bpad>
                  <Column fill>
                    <table style={{ width: "680px" }}>
                      <thead>
                        <tr>
                          <th>Date de début</th>
                          <th>Date de fin</th>
                          <th>Activité</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {periodesForRetraiteProgressive.map((Infos, Index) => {
                          return (
                            <tr key={Index}>
                              <td>{applyFormat(Infos.debut, "date", "")}</td>
                              <td>{applyFormat(Infos.fin, "date", "")}</td>
                              <td>{CorrespondanceActivite[Infos.nature]}</td>
                              <td>
                                <StateBox value={Infos.selected} onClick={handleSelectActiviteRetraiteProgressive(Infos.id)} normal />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Column>
                </Row>
                <Modal
                  width="45%"
                  height="25%"
                  visible={modalErrorDateDebut}
                  onClose={() => {
                    setModalErrorDateDebut(false);
                  }}
                >
                  <Column fill style={{ justifyContent: "center" }}>
                    <CardBox>
                      <Column fill>
                        <Row>
                          <Column fill style={{ textAlign: "center" }}>
                            La date de début ne peut pas être inférieure à la date du {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint]["DateDebutRetraiteProgressive"], "date")}{" "}
                            (âge légal moins 2 ans).
                          </Column>
                        </Row>
                      </Column>
                    </CardBox>
                  </Column>
                </Modal>

                <Modal
                  width="45%"
                  height="25%"
                  visible={modalErrorDate1AnMin}
                  onClose={() => {
                    setModalErrorDate1AnMin(false);
                  }}
                >
                  <Column fill style={{ justifyContent: "center" }}>
                    <CardBox>
                      <Column fill>
                        <Row>
                          <Column fill style={{ textAlign: "center" }}>
                            La période de la retraite progressive doit être au minimum d'un an.
                          </Column>
                        </Row>
                      </Column>
                    </CardBox>
                  </Column>
                </Modal>

                <Modal
                  width="45%"
                  height="25%"
                  visible={modalDateDepartTNS}
                  onClose={() => {
                    setModalDateDepartTNS(false);
                  }}
                >
                  <Column fill style={{ justifyContent: "center" }}>
                    <CardBox>
                      <Column fill>
                        <Row>
                          <Column fill style={{ textAlign: "center" }}>
                            Le service de la fraction de pension des assurés TNS ou assimilés salariés prend obligatoirement effet au 1er janvier qui suit la demande. Nous vous invitons à modifier la date de début de la retraite progressive en conséquence.
                          </Column>
                        </Row>
                      </Column>
                    </CardBox>
                  </Column>
                </Modal>
                {periodesForRetraiteProgressive.filter(
                  (periode) =>
                    (periode.nature === "SalarieNonCadre" ||
                      periode.nature === "SalarieCadre" ||
                      periode.nature === "SalarieNonCadreCFE" ||
                      periode.nature === "SalarieCadreCFE" ||
                      periode.nature === "SalarieNonCadreAgricole" ||
                      periode.nature === "SalarieCadreAgricole" ||
                      periode.nature === "SalarieIrcantec" ||
                      periode.nature === "CavecSalarie" ||
                      periode.nature === "CnbfSalarie") &&
                    periode.selected === true
                ).length > 0 && (
                  /* SALARIE */
                  <>
                    <Row tpad>
                      <Column>
                        <TextField
                          disabled
                          className="invisibleRachatTrimestre-left"
                          style={{ color: "black", width: "400px" }}
                          value={"Salaire brut annuel dans le cadre de la retraite progressive"}
                        />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveRevenus" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveRevenus" + ClientConjoint)}
                          normal
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                        />
                      </Column>
                      {/* <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>
                          Indiquez le montant des revenus professionnels des 3 années qui précèdent la demande pour déterminer le cout du rachat. Montant des salaires et le cas échéant des
                          revenus d'activité non salariée soumis à cotisations de sécurité sociale, non limités au plafond. Les autres revenus n'ont pas à être déclarés.
                        </Hover>
                      </Column> */}
                    </Row>
                    <Row tpad>
                      <Column>
                        <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black", width: "400px" }} value={"Taux d'activité"} />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveTauxActivite" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveTauxActivite" + ClientConjoint)}
                          onBlur={(e) => {
                            if (e.target.value < 40) {
                              context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 40);
                            } else if (e.target.value > 80) {
                              context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 80);
                            }
                          }}
                          normal
                          dataType="float"
                          format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                        />
                      </Column>
                      <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>Le taux d'activité doit être compris entre 40% et 80%.</Hover>
                      </Column>
                    </Row>
                  </>
                )}

                {periodesForRetraiteProgressive.filter(
                  (periode) =>
                    (periode.nature === "RsiArtisan" ||
                      periode.nature === "Cnbf" ||
                      periode.nature === "Carpimko" ||
                      periode.nature === "Carcdsf" ||
                      periode.nature === "RsiCommercant" ||
                      periode.nature === "Cavec" ||
                      periode.nature === "Cavamac" ||
                      periode.nature === "Crn" ||
                      periode.nature === "Cipav" ||
                      periode.nature === "Cavom" ||
                      periode.nature === "Carmf" ||
                      periode.nature === "Cavp" ||
                      periode.nature === "Carcdsf2" ||
                      periode.nature === "Carpv") &&
                    periode.selected === true
                ).length > 0 && (
                  /* TNS */
                  <>
                    <Row tpad>
                      <Column>
                        <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black", width: "400px" }} value={"Moyenne des revenus des 5 dernières années"} />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveMoyenneRevenus" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveMoyenneRevenus" + ClientConjoint)}
                          onBlur={(e) => {
                            if (Number(e.target.value) > 0) {
                              if (Number(e.target.value) / Number(context.state["RetraiteProgressiveRevenus" + ClientConjoint]) < 1.25) {
                                context.handleValueChange("RetraiteProgressiveRevenus" + ClientConjoint, Number(Number(e.target.value) / 1.25).toFixed(0));
                              } else if (Number(e.target.value) / Number(context.state["RetraiteProgressiveRevenus" + ClientConjoint]) > 2.5) {
                                context.handleValueChange("RetraiteProgressiveRevenus" + ClientConjoint, Number(Number(e.target.value) / 2.5).toFixed(0));
                              }
                            }
                          }}
                          normal
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                        />
                      </Column>
                      <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>
                          Le pourcentage de réduction des revenus est calculé au 1er juillet de chaque année en fonction du rapport existant entre les revenus de l'année précédente tels
                          qu'ils sont retenus pour l'assiette de l'impôt sur le revenu et la moyenne de ces mêmes revenus professionnels des cinq années précédant la demande de retraite
                          progressive actualisés en fonction des coefficients de revalorisation visés à l'article L. 161-25 du code de la sécurité sociale.
                        </Hover>
                      </Column>
                    </Row>
                    <Row tpad>
                      <Column>
                        <TextField
                          disabled
                          className="invisibleRachatTrimestre-left"
                          style={{ color: "black", width: "400px" }}
                          value={"Revenu annuel dans le cadre de la retraite progressive"}
                        />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveRevenus" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveRevenus" + ClientConjoint)}
                          onBlur={(e) => {
                            if (Number(e.target.value) < Number(context.state["RetraiteProgressiveMoyenneRevenus" + ClientConjoint] * 0.4).toFixed(0)) {
                              context.handleValueChange(
                                "RetraiteProgressiveRevenus" + ClientConjoint,
                                Number(context.state["RetraiteProgressiveMoyenneRevenus" + ClientConjoint] * 0.4).toFixed(0)
                              );
                            } else if (Number(e.target.value) > Number(context.state["RetraiteProgressiveMoyenneRevenus" + ClientConjoint] * 0.8).toFixed(0)) {
                              context.handleValueChange(
                                "RetraiteProgressiveRevenus" + ClientConjoint,
                                Number(context.state["RetraiteProgressiveMoyenneRevenus" + ClientConjoint] * 0.8).toFixed(0)
                              );
                            }
                          }}
                          normal
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                        />
                      </Column>
                      <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>Le revenu doit avoir été diminué de 20% minimum et de 60% maximum. Le revenu de référence doit être au moins supérieur à 40% du Smic.</Hover>
                      </Column>
                    </Row>
                  </>
                )}
                {periodesForRetraiteProgressive.filter((periode) => periode.nature === "Msa" && periode.selected === true).length > 0 && (
                  /* MSA */
                  <>
                    <Row tpad>
                      <Column>
                        <TextField
                          disabled
                          className="invisibleRachatTrimestre-left"
                          style={{ color: "black", width: "400px" }}
                          value={"Revenu annuel dans le cadre de la retraite progressive"}
                        />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveRevenus" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveRevenus" + ClientConjoint)}
                          normal
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                        />
                      </Column>
                      {/* <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>
                          Indiquez le montant des revenus professionnels des 3 années qui précèdent la demande pour déterminer le cout du rachat. Montant des salaires et le cas échéant des
                          revenus d'activité non salariée soumis à cotisations de sécurité sociale, non limités au plafond. Les autres revenus n'ont pas à être déclarés.
                        </Hover>
                      </Column> */}
                    </Row>
                    <Row tpad>
                      <Column>
                        <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black", width: "400px" }} value={"Taux de réduction d'activité"} />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveTauxActivite" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveTauxActivite" + ClientConjoint)}
                          onBlur={(e) => {
                            if (e.target.value < 20) {
                              context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 20);
                            } else if (e.target.value > 60) {
                              context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 60);
                            }
                          }}
                          normal
                          dataType="float"
                          format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                        />
                      </Column>
                      <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>
                          La diminution de l’activité est exprimée selon les cas en nombre d’hectares cédés, en nombres d’heures de travail ou en diminution du nombre de parts sociales
                          cédées par un membre d'une société.
                        </Hover>
                      </Column>
                    </Row>
                  </>
                )}
                {periodesForRetraiteProgressive.filter((periode) => periode.nature === "FonctionPublique" && periode.selected === true).length > 0 && (
                  /* FONCTIONNAIRE */
                  <>
                    <Row tpad>
                      <Column>
                        <TextField
                          disabled
                          className="invisibleRachatTrimestre-left"
                          style={{ color: "black", width: "400px" }}
                          value={"Traitement brut annuel dans le cadre de la retraite progressive"}
                        />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveRevenus" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveRevenus" + ClientConjoint)}
                          normal
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                        />
                      </Column>
                      {/* <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>
                          Indiquez le montant des revenus professionnels des 3 années qui précèdent la demande pour déterminer le cout du rachat. Montant des salaires et le cas échéant des
                          revenus d'activité non salariée soumis à cotisations de sécurité sociale, non limités au plafond. Les autres revenus n'ont pas à être déclarés.
                        </Hover>
                      </Column> */}
                    </Row>
                    <Row tpad>
                      <Column>
                        <TextField disabled className="invisibleRachatTrimestre-left" style={{ color: "black", width: "400px" }} value={"Taux d'activité"} />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveTauxActivite" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveTauxActivite" + ClientConjoint)}
                          onBlur={(e) => {
                            if (e.target.value < 50) {
                              context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 50);
                            } else if (e.target.value > 90) {
                              context.handleValueChange("RetraiteProgressiveTauxActivite" + ClientConjoint, 90);
                            }
                          }}
                          normal
                          dataType="float"
                          format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                        />
                      </Column>
                      <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>Le taux d'activité doit être compris entre 10% et 50%.</Hover>
                      </Column>
                    </Row>
                    <Row tpad>
                      <Column style={{ paddingRight: "6px" }}>
                        <TextField
                          disabled
                          className="invisibleRachatTrimestre-left"
                          style={{ color: "black", width: "402px" }}
                          value={"Indice majoré de fin de carrière au moment de la liquidation définitive"}
                        />
                      </Column>
                      <Column rpad>
                        <TextField
                          value={context.state["RetraiteProgressiveIFCFin" + ClientConjoint]}
                          onChange={context.handleInputChange("RetraiteProgressiveIFCFin" + ClientConjoint)}
                          normal
                          dataType="uint"
                          format={{ thousands: " " }}
                        />
                      </Column>
                      <Column style={{ paddingTop: "14px" }} lpad>
                        <Hover icon={<FaInfo />}>
                          Par défaut, le logiciel reprend l'indice saisi dans la carrière de l'assuré. Attention, en fonction de la date de départ, l'indice peut évoluer.
                        </Hover>
                      </Column>
                    </Row>
                  </>
                )}
                <Row tpad>
                  <Column tpad fill center>
                    <Button
                      // disabled={context.state["RachatRevenus" + ClientConjoint].toString() === "0" ? true : false}
                      onClick={() => {
                        ResultatTNS.GetResultRetraiteProgressive(
                          context.state,
                          periodesForRetraiteProgressive.filter((periode) => periode.selected === true)[0],
                          context.state["CarriereSalaireReference" + ClientConjoint],
                          ClientConjoint
                        );
                      }}
                    >
                      <FaPrint />
                      <span>Imprimer</span>
                    </Button>
                  </Column>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>
    </>
  );
};

export default RetraiteProgressivePage;
