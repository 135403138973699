import React, { useEffect } from "react";

import { useContext, useState } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Pager from "components/ui/controls/pager/pager";
import ModalEpgAcquise from "../ModalEpgAcquise";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaInfo, FaSync } from "react-icons/fa";
import Hover from "components/ui/controls/hover/hover";

function ParametresPerco(props) {
  const { TabTauxConversion } = props;
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);

  const InitialAnneeAcquisition = () => {
    const dt = new Date();

    return dt.getFullYear() - 1;
  };

  useEffect(() => {
    if (context.state.SetTauxConversionContratRetraite === false) {
      if (context.state["DateNaissance" + props.ClientConjoint].slice(-4) in TabTauxConversion) {
        if (Math.min(70, context.state.AgeDepartRetraite.slice(0, 2)) in TabTauxConversion[context.state["DateNaissance" + props.ClientConjoint].slice(-4)]) {
          context.handleValueChange(
            "TauxConversionContratRetraite",
            TabTauxConversion[context.state["DateNaissance" + props.ClientConjoint].slice(-4)][Math.min(70, Number(context.state.AgeDepartRetraite.slice(0, 2)))]
          );
        } else {
          context.handleValueChange("TauxConversionContratRetraite", 0.0);
        }
      } else {
        context.handleValueChange("TauxConversionContratRetraite", 0.0);
      }
    }
  }, [context.state.SetTauxConversionContratRetraite]);
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <Column>
                <TextField
                  label={"Epargne acquise au 31/12/" + InitialAnneeAcquisition()}
                  value={Number(context.state.EpargneAcquiseVersementContratRetraite) + Number(context.state.EpargneAcquiseProduitContratRetraite)}
                  dataType="uint"
                  format={{ thousands: " ", unit: " €" }}
                  normal
                  readOnly
                  onClick={() => setmodalOpen(true)}
                />
                <ModalEpgAcquise
                  visible={modalOpen}
                  onClose={() => {
                    setmodalOpen(false);
                  }}
                />
              </Column>
              <Column>
                <TextField
                  label="Rendement net de l’épargne"
                  value={context.state.RendementContratRetraite}
                  onChange={context.handleInputChange("RendementContratRetraite")}
                  dataType="ufloat"
                  format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <TextField
                  label="Frais sur versement"
                  value={context.state.FraisVersementContratRetraite}
                  onChange={context.handleInputChange("FraisVersementContratRetraite")}
                  dataType="ufloat"
                  format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                  normal
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <DropDown
                  label="Sortie"
                  value={context.state.SortieContratRetraite}
                  options={[
                    { value: "RenteSimple", label: "Rente" },
                    { value: "Capital", label: "Capital" },
                    { value: "Panachage", label: "Panachage des deux" },
                  ]}
                  onChange={context.handleInputChange("SortieContratRetraite")}
                  normal
                />
              </Column>
            </Row>
            {(context.state.SortieContratRetraite === "RenteSimple" || context.state.SortieContratRetraite === "Panachage") && (
              <>
                <Row>
                  <Column>
                    <TextField
                      iconBefore={
                        <Button
                          tabIndex="-1"
                          icon
                          onClick={() => {
                            context.handleValueChange("SetTauxConversionContratRetraite", false);
                          }}
                        >
                          {context.state["SetTauxConversionContratRetraite"] === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                      label="Taux de conversion"
                      value={context.state.TauxConversionContratRetraite}
                      onChange={(e) => {
                        context.handleValueChange("SetTauxConversionContratRetraite", true);
                        context.handleValueChange("TauxConversionContratRetraite", e.target.value);
                      }}
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                      normal
                    />
                  </Column>
                  <Column style={{ paddingTop: "40px" }} lpad>
                    <Hover style={{ width: "500px", textAlign: "justify", padding: "14px" }} icon={<FaInfo />}>
                      Le logiciel détermine automatiquement le taux de conversion applicable en fonction de l'année de naissance de votre client et de son âge de départ en retraite. Le taux
                      est basé sur une table de mortalité équivalente à la TGF05. Pour plus d'informations sur la table utilisée, vous pouvez télécharger le document.
                    </Hover>
                  </Column>
                  <Column style={{ paddingTop: "40px" }} lpad>
                    <a href="/static/TGF05.pdf" target="_blank">Table de mortalité TGF05</a>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <TextField
                      label="Frais sur arrérages"
                      value={context.state.FraisArrerageContratRetraite}
                      onChange={context.handleInputChange("FraisArrerageContratRetraite")}
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                      normal
                    />
                  </Column>
                </Row>
              </>
            )}
            {context.state.SortieContratRetraite === "Panachage" && (
              <Row>
                <Column>
                  <TextField
                    label="Part en capital"
                    value={context.state.PartCapitalPERContratRetraite}
                    onChange={context.handleInputChange("PartCapitalPERContratRetraite")}
                    dataType="ufloat"
                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    normal
                  />
                </Column>
              </Row>
            )}
            {(context.state.SortieContratRetraite === "Capital" || context.state.SortieContratRetraite === "Panachage") && (
              <Row>
                <Column>
                  <TextField
                    label="Durée du fractionnement"
                    value={context.state.DureeFractionnementContratRetraite}
                    onChange={context.handleInputChange("DureeFractionnementContratRetraite")}
                    dataType="uint"
                    format={{ thousands: " ", unit: " ans" }}
                    normal
                  />
                </Column>
                <Column>
                    <TextField
                      label="Revalorisation du capital"
                      labelStyle={{ whiteSpace: "nowrap" }}
                      value={context.state.RevalorisationCapitalContratRetraite}
                      onChange={context.handleInputChange("RevalorisationCapitalContratRetraite")}
                      normal
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    />
                  </Column>
              </Row>
            )}
            {(context.state.SortieContratRetraite === "RenteSimple" || context.state.SortieContratRetraite === "Panachage") && (
              <>
                <Row>
                  <Column>
                    <DropDown
                      label="Réversion"
                      value={context.state.ReversionContratRetraite}
                      options={[
                        { value: "non", label: "Non" },
                        { value: "oui", label: "Oui" },
                      ]}
                      onChange={context.handleInputChange("ReversionContratRetraite")}
                      normal
                    />
                  </Column>
                </Row>

                {context.state.ReversionContratRetraite === "oui" && (
                  <Row>
                    <Column>
                      <TextField
                        label="Taux de réversion"
                        value={context.state.TauxReversionContratRetraite}
                        onChange={context.handleInputChange("TauxReversionContratRetraite")}
                        dataType="ufloat"
                        format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                        normal
                      />
                    </Column>
                  </Row>
                )}
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}
export default ParametresPerco;
