import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaInfo, FaSync } from "react-icons/fa";
import CheckBox from "components/ui/controls/check-box/check-box";
import Hover from "components/ui/controls/hover/hover";

const MajorationEnfantRetraite = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <blockquote>
                Nous vous informons que les majorations enfants <b>sont attribuées par défaut au régime général. </b>
                Toutefois, nous vous rappelons que lorsque qu’un assuré a cotisé au régime général et auprès d’un régime spécial (Fonction publique ou CRPCEN),{" "}
                <b>c’est en principe le régime spécial qui accorde la majoration </b>
                de trimestres si les conditions d’attribution sont remplies (ex : cessation d’activité…). Pour tenir compte de ces règles, vous pouvez modifier manuellement la majoration
                dans les différents régimes.
              </blockquote>
            </Row>
            <Row tpad>
              <TextField
                iconBefore={
                  <Button
                    icon
                    onClick={(e) => {
                      context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisi" + ClientConjoint, ClientConjoint, "");
                    }}
                  >
                    {!context.state["CarriereMajorationEnfantSaisi" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                  </Button>
                }
                label="Régime général"
                value={context.state["CarriereMajorationEnfant" + ClientConjoint]}
                onKeyUp={(e) => {
                  context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisi" + ClientConjoint, ClientConjoint, e.target.value);
                }}
                onChange={context.handleInputChange("CarriereMajorationEnfant" + ClientConjoint)}
                dataType="uint"
              />
            </Row>
            <Row tpad>
              <TextField
                iconBefore={
                  <Button
                    icon
                    onClick={(e) => {
                      context.handleCarriereMajorationEnfantSaisi("TrimestreBonificationEnfantSaisiFonctionPublique" + ClientConjoint, ClientConjoint, "");
                    }}
                  >
                    {!context.state["TrimestreBonificationEnfantSaisiFonctionPublique" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                  </Button>
                }
                label="Fonction publique avant 2004"
                value={context.state["TrimestreBonificationEnfantFonctionPublique" + ClientConjoint]}
                onKeyUp={(e) => {
                  context.handleCarriereMajorationEnfantSaisi("TrimestreBonificationEnfantSaisiFonctionPublique" + ClientConjoint, ClientConjoint, e.target.value);
                }}
                onChange={context.handleInputChange("TrimestreBonificationEnfantFonctionPublique" + ClientConjoint)}
                dataType="uint"
              />
            </Row>
            <Row tpad>
              <TextField
                iconBefore={
                  <Button
                    icon
                    onClick={(e) => {
                      context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisiFonctionPublique" + ClientConjoint, ClientConjoint, "");
                    }}
                  >
                    {!context.state["CarriereMajorationEnfantSaisiFonctionPublique" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                  </Button>
                }
                label="Fonction publique à partir de 2004"
                value={context.state["CarriereMajorationEnfantFonctionPublique" + ClientConjoint]}
                onKeyUp={(e) => {
                  context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisiFonctionPublique" + ClientConjoint, ClientConjoint, e.target.value);
                }}
                onChange={context.handleInputChange("CarriereMajorationEnfantFonctionPublique" + ClientConjoint)}
                dataType="uint"
              />
            </Row>
            <Row tpad>
              <TextField
                iconBefore={
                  <Button
                    icon
                    onClick={(e) => {
                      context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisiCrpcen" + ClientConjoint, ClientConjoint, "");
                    }}
                  >
                    {!context.state["CarriereMajorationEnfantSaisiCrpcen" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                  </Button>
                }
                label="CRPCEN avant juillet 2006"
                value={context.state["CarriereMajorationEnfantCrpcen" + ClientConjoint]}
                onKeyUp={(e) => {
                  context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisiCrpcen" + ClientConjoint, ClientConjoint, e.target.value);
                }}
                onChange={context.handleInputChange("CarriereMajorationEnfantCrpcen" + ClientConjoint)}
                dataType="uint"
              />
            </Row>
            <Row tpad bpad>
              <TextField
                iconBefore={
                  <Button
                    icon
                    onClick={(e) => {
                      context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisiCrpcenApres2006" + ClientConjoint, ClientConjoint, "");
                    }}
                  >
                    {!context.state["CarriereMajorationEnfantSaisiCrpcenApres2006" + ClientConjoint] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                  </Button>
                }
                label="CRPCEN à partir de juillet 2006"
                value={context.state["CarriereMajorationEnfantCrpcenApres2006" + ClientConjoint]}
                onKeyUp={(e) => {
                  context.handleCarriereMajorationEnfantSaisi("CarriereMajorationEnfantSaisiCrpcenApres2006" + ClientConjoint, ClientConjoint, e.target.value);
                }}
                onChange={context.handleInputChange("CarriereMajorationEnfantCrpcenApres2006" + ClientConjoint)}
                dataType="uint"
              />
            </Row>
            <Row tpad>
              <blockquote tpad>
                <b>Le calcul des majorations enfant à l’AGIRC-ARRCO n’est pas lancé automatiquement.</b> En effet, le RIS ne permet pas de distinguer les points ARRCO des points AGIRC et c’est
                pourquoi le logiciel intègre les points acquis dans les points AGIRC-ARRCO à compter de 2019. Or, les taux de majoration des points dépendent du régime et de la date
                d’acquisition. Si vous souhaitez intégrer le calcul des majorations enfant, nous vous conseillons vivement de disposer d’un ancien relevé de points AGIRC ARRCO.
              </blockquote>
            </Row>
            <Row tpad>
              <Column style={{ paddingLeft: 0, marginLeft: "-6px" }}>
                <CheckBox
                  label="Calcul de la majoration enfant AGIRC ARRCO"
                  inline
                  checked={true}
                  unchecked={false}
                  value={context.state["UnlockMajoEnfantAgircArrco" + ClientConjoint]}
                  onChange={context.handleCheckChange("UnlockMajoEnfantAgircArrco" + ClientConjoint)}
                />
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default MajorationEnfantRetraite;
