import { useState, useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaCalendarDay, FaCoins, FaHistory, FaHouseUser,  FaSun } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import DetailPension from "pages/audit complet/résultat/detail/retraite/DetailPension";
import DetailRAM from "pages/audit complet/résultat/detail/retraite/DetailRAM";
import DetailSAMCRPCEN from "pages/audit complet/résultat/detail/retraite/DetailSAMCRPCEN";
import DetailTrimestre from "pages/audit complet/résultat/detail/retraite/DetailTrimestre";
import DetailDepart from "pages/audit complet/résultat/detail/retraite/DetailDepart";
import DetailReversion from "pages/audit complet/résultat/detail/retraite/DetailReversion";
import DetailTrimestreRU from "pages/audit complet/résultat/detail/retraite/DetailTrimestreRU";

const DetailRetraitePageModal = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);

  const handleMenu = () => {
    let menu = [
      {
        label: "Retraite",
        icon: <FaSun />,
        content: <DetailPension Outil={""} InfosPension={props.InfosRetraiteChoixDepart} />,
      },
      {
        label: "RAM",
        icon: <FaCoins />,
        content: <DetailRAM Outil={""} InfosRAM={props.InfosRetraiteChoixDepart?.InfosRetraite} />,
      },
      {
        label: "SAM CRPCEN",
        icon: <FaCoins />,
        content: <DetailSAMCRPCEN Outil={""} InfosRAM={props.InfosRetraiteChoixDepart?.InfosRetraite} />,
      },
      {
        label: "Trimestres tous régimes",
        icon: <FaHistory />,
        content: <DetailTrimestre Outil={""} InfosTrimestres={props.InfosRetraiteChoixDepart} />,
      },
      {
        label: "Trimestres régime général",
        icon: <FaHistory />,
        content: <DetailTrimestreRU Outil={""} InfosTrimestres={props.InfosRetraiteChoixDepart} />,
      },
      {
        label: "Départ",
        icon: <FaCalendarDay />,
        content: <DetailDepart Outil={""} InfosDepart={props.InfosRetraite} ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Réversions",
        icon: <FaHouseUser />,
        content: <DetailReversion Outil={""} InfosDepart={props.InfosRetraite} />,
      },
    ];

    if (!Boolean(props.InfosRetraiteChoixDepart?.InfosRetraite?.RegimeUnifie)) {
      menu = menu.filter((k) => k.label !== "RAM");
    }
    if (!Boolean(props.InfosRetraiteChoixDepart?.InfosRetraite?.Crpcen?.General?.DetailSAMCrpcen)) {
      
      menu = menu.filter((k) => k.label !== "SAM CRPCEN");
    }
    if (Boolean(props.InfosRetraiteChoixDepart?.InfosRetraite?.Crpcen?.General?.DetailSAMCrpcen) && props.InfosRetraiteChoixDepart?.InfosRetraite?.Crpcen?.General?.SAM===0) {
      menu = menu.filter((k) => k.label !== "SAM CRPCEN");
    }
    // if (context.state.StatutMarital !== "marie") {
    //   menu = menu.filter((k) => k.label !== "Réversions");
    // }
    if (props.InfosRetraiteChoixDepart?.TotalPensionBrute===0) {
      
      menu = menu.filter((k) => k.label !== "Départ");
      menu = menu.filter((k) => k.label !== "Réversions");
    }
    return menu;
  };
  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);
  return (
    <>
      <Pager
        withMenu
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default DetailRetraitePageModal;
