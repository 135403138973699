import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import TextField from "components/ui/controls/text-field/text-field";
import LigneImpotDetaille from "./LigneImpot";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";

const BicBncAvantAbattement = (props) => {
  const { Outil } = props;
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const Periode = context.state[Outil + "PeriodeActuelle"] === undefined ? undefined : context.state[Outil + "PeriodeActuelle"][0];
  const PeriodeConjoint = context.state[Outil + "PeriodeActuelleConjoint"] === undefined ? undefined : context.state[Outil + "PeriodeActuelleConjoint"][0];

  let RemunerationDeclaree=0;
if( Periode?.Statut === "TNS individuel"){
  if(context.state.TypeSimulation === "Audit rémunération"){
    if(Outil === ""){
      if(context.state.ModeExercice==="IS"){
        RemunerationDeclaree=Number(ResultatTNS.state["RemExpressRemuneration"]?.BrutImposable)-Number(ResultatTNS.state["RemExpressRemuneration"]?.BrutImposable)*context.state.PartRemunerationMandat/100
      }else{
        RemunerationDeclaree=ResultatTNS.state["RemExpressRemuneration"]?.BrutImposable
      }
    }else if (Outil === "OptiRem"){
      RemunerationDeclaree=ResultatTNS.state.OptiRemRemuneration?.BrutImposable
    }else{
      RemunerationDeclaree=ResultatTNS.state[Outil + "RemunerationTNS"]?.BrutImposable
    }
  }
}

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <table className="mono">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div className="normal text-center">Dirigeant</div>
                  </th>
                  <th>
                    <div className="normal text-center">Conjoint</div>{" "}
                  </th>
                </tr>
              </thead>
              <tbody>
                {Periode === undefined ? (
                  ""
                ) : (
                  <tr>
                    <td>Rémunération déclarée sur l'activité étudiée</td>
                    <td>
                      <TextField
                        // value={
                        //   Periode?.Statut === "TNS individuel"
                        //     ? context.state.TypeSimulation === "Audit rémunération" && Outil === ""
                        //       ? ResultatTNS.state["RemExpressRemuneration"]?.BrutImposable
                        //       : Outil === "OptiRem"
                        //       ? ResultatTNS.state.OptiRemRemuneration?.BrutImposable
                        //       : ResultatTNS.state[Outil + "RemunerationTNS"]?.BrutImposable
                        //     : 0
                        // }
                        value={RemunerationDeclaree}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                        disabled={true}
                      />
                    </td>
                    <td>
                      {context.state.TypeSimulation !== "Audit rémunération" && (
                        <TextField
                          value={
                            Periode?.StatutConjoint === "TNS individuel" || PeriodeConjoint?.Statut === "TNS individuel"
                              ? Outil === "OptiRem"
                                ? ResultatTNS.state.OptiRemRemunerationConjoint?.BrutImposable
                                : ResultatTNS.state[Outil + "RemunerationTNSConjoint"].BrutImposable
                              : 0
                          }
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          normal
                          disabled={true}
                        />
                      )}
                    </td>
                  </tr>
                )}
                <LigneImpotDetaille Libelle="BNC régime micro" VarName1={Outil + "IRDirigeantBNCMicro"} VarName2={Outil + "IRConjointBNCMicro"} />
                <LigneImpotDetaille Libelle="BNC régime de la déclaration contrôlée" VarName1={Outil + "IRDirigeantBNCControlle"} VarName2={Outil + "IRConjointBNCControlle"} />
                <LigneImpotDetaille
                  Libelle="BIC régime micro : activité d'achat revente, logement"
                  VarName1={Outil + "IRDirigeantBICMicroAchatRevente"}
                  VarName2={Outil + "IRConjointBICMicroAchatRevente"}
                />
                <LigneImpotDetaille Libelle="BIC régime micro : activité de service" VarName1={Outil + "IRDirigeantBICMicroService"} VarName2={Outil + "IRConjointBICMicroService"} />
                <LigneImpotDetaille Libelle="BIC régime réel simplifié ou normal" VarName1={Outil + "IRDirigeantBICReel"} VarName2={Outil + "IRConjointBICReel"} />
                <LigneImpotDetaille Libelle="BA régime micro" VarName1={Outil + "IRDirigeantBAMicro"} VarName2={Outil + "IRConjointBAMicro"} />
                <LigneImpotDetaille Libelle="BA régime réel simplifié ou normal" VarName1={Outil + "IRDirigeantBAReel"} VarName2={Outil + "IRConjointBAReel"} />
              </tbody>
            </table>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default BicBncAvantAbattement;
